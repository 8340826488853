/**
 * Update date: 2024-07-11
 * Screen 2024.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';

interface IPopupInputDeliveryBoxQty {
  onOk?: (data?: any) => void;
}
const PopupInputDeliveryBoxQty = ({ onOk }: IPopupInputDeliveryBoxQty, ref) => {
  const { t }: any = useDictionary({ programId: '2024.3' });
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [info, setInfo] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [receiveData, setReceiveData] = useState<any>({});

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  /**
   * on save order
   *
   * @return {*}
   */
  const onSaveOrder = async () => {
    const newOrders = products?.filter(o => o?.receiveQuantity);
    // check Supply amount + VAT = Amount
    if (!newOrders.length) {
      return notification({
        message: t(
          'You must be input quantity at least one record. Please try again!',
        ),
        type: 'error',
      });
    }
    const newData = {
      ...info,
      receiveDate: receiveData.receiveDate,
      referenceCd: receiveData.referenceCd,
      receiveType: info?.receiveTp,
      receiveDetails: newOrders,
    };
    const res = await refetchData({
      url: `warehouse/consigned-delivery/save-input-delivery/box-qty`,
      method: 'POST',
      data: newData,
    });
    notification({ res });
    if (res?.data?.status == '200') {
      onOk?.();
      onClickNo();
    }
  };

  const getSettingFromData = async dataRequest => {
    const res = await refetchData({
      url: `warehouse/consigned-delivery/get-input-delivery/box-qty`,
      method: 'GET',
      params: dataRequest,
    });
    if (res?.data?.status === '200') {
      const { master, details } = res?.data?.data || {};
      setInfo(master);
      setProducts((details || []).map((o, idx) => ({ ...o, ID: idx + 1 })));
    } else {
      notification({ res });
    }
  };

  const onOpen = data => {
    if (data?.vendorId && data?.receiveIds) {
      setShowPopupConfirm(true);
      getSettingFromData(data);
      setReceiveData({ ...data });
    }
  };

  const onClickNo = () => {
    setShowPopupConfirm(false);
    setInfo(null);
    setProducts([]);
  };

  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <div className="modal-popup-header">
            <span className="title-page">{t('Input Delivery Box Qty')}</span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 5 }}
                onClick={onSaveOrder}
              />
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={onClickNo}
              />
            </div>
          </div>
          <DataGridFull
            ref={dataGridRef}
            dataSource={products}
            fixedLeft={2}
            fixedRight={2}
            columns={[
              {
                dataField: 'ID',
                caption: t('ID'),
                alignment: 'left',
                allowEditing: false,
                width: 50,
                fixedPosition: 'left',
                fixed: true,
                allowHeaderFiltering: false,
                allowFiltering: false,
              },
              {
                dataField: 'productCd',
                caption: t('Product Code'),
                alignment: 'left',
                allowEditing: false,
                fixedPosition: 'left',
                fixed: true,
              },
              {
                dataField: 'productNm',
                caption: t('Product Name'),
                alignment: 'left',
                allowEditing: false,
              },
              {
                dataField: 'optionNm',
                caption: t('Option'),
                alignment: 'left',
                allowEditing: false,
              },
              {
                dataField: 'receiveQuantity',
                caption: t('Qty'),
                format: QtyFormat,
                dataType: 'number',
                allowEditing: true,
                width: 120,
                editorOptions: {
                  format: QtyFormat,
                  step: 0,
                },
                cssClass: 'gridcell-editing',
              },
              {
                dataField: 'purchaseUnitPrice',
                caption: t('Purchase Unit Price'),
                allowEditing: false,
                format: DoubleFormat,
                dataType: 'number',
                width: 160,
                editorOptions: {
                  step: 0,
                },
              },
            ]}
            options={{
              columnAutoWidth: true,
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 110px)',
              editing: {
                mode: 'cell',
                allowUpdating: true,
                selectTextOnEditStart: true,
              },
              onEditorPreparing: function (e) {
                if (
                  e?.parentType === 'dataRow' &&
                  e?.dataField === 'receiveQuantity'
                ) {
                  e.editorOptions.onKeyDown = function (ekey) {
                    if (ekey?.event?.keyCode == 13) {
                      if (ekey?.event?.keyCode == 13) {
                        const dataGrid = dataGridRef?.current?.instance;
                        const nextElement = dataGrid?.getCellElement(
                          e?.row?.rowIndex + 1,
                          e?.dataField,
                        );
                        if (nextElement) {
                          ekey?.event?.stopPropagation();
                          dataGrid?.focus(nextElement);
                          nextElement?.click();
                        }
                      }
                    }
                  };
                }
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              summary: {
                totalItems: [
                  {
                    column: 'receiveQuantity',
                    summaryType: 'sum',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'receiveAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'supplyAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'vatAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'bottleAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'containerAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'totalAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                ],
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <>
      <Popup
        className="modal-content-popup"
        visible={showPopupConfirm}
        contentRender={content}
        height={isMobile ? '80vh' : '100vh'}
        width={isMobile ? '96%' : '65vw'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
      <LoadPanel visible={loading} />
    </>
  );
};

export default memo(forwardRef(PopupInputDeliveryBoxQty));
