/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from 'devextreme-react/chart';

const defaultValue = [
  {
    category: '',
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
    value5: 0,
  },
];

const ChartCountQuantityStock = ({
  t,
  data = defaultValue,
  totalProduct = 100,
}) => {
  const barWidth = 35;
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#fff',
        paddingLeft: 75,
        paddingRight: 10,
        paddingBottom: 20,
      }}
    >
      <Chart
        id="stacked-bar-chart"
        dataSource={data}
        rotated={true}
        size={{
          height: 150,
        }}
      >
        <ArgumentAxis />
        <ValueAxis
          title={t('Total product')}
          visualRange={{
            startValue: 0,
            endValue: totalProduct,
          }}
          minorTick={{ visible: false }}
          tickInterval={totalProduct <= 20 ? 1 : ''}
        />
        <Series
          valueField="value1"
          argumentField="category"
          name="Series 1"
          type="stackedBar"
          color="#8B0000"
          barWidth={barWidth}
        />
        <Series
          valueField="value2"
          argumentField="category"
          name="Series 2"
          type="stackedBar"
          color="#A52A2A"
          barWidth={barWidth}
        />
        <Series
          valueField="value3"
          argumentField="category"
          name="Series 3"
          type="stackedBar"
          color="#FF8C00"
          barWidth={barWidth}
        />
        <Series
          valueField="value4"
          argumentField="category"
          name="Series 4"
          type="stackedBar"
          color="#FFA500"
          barWidth={barWidth}
        />
        <Series
          valueField="value5"
          argumentField="category"
          name="Series 5"
          type="stackedBar"
          color="#FFD700"
          barWidth={barWidth}
        />
        <Tooltip enabled={true} />
        <Legend visible={false} />
      </Chart>
    </div>
  );
};

export default ChartCountQuantityStock;
