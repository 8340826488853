import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import { LOST_OTHER_ACCOUNTS_FORWARDING_URL } from 'constant/apiUrl';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import useDictionary from 'hooks/useDictionary';

const CreateAccountsForwarding = React.lazy(
  () => import('./CreateAccountsForwarding'),
);
const ListAccountsForwarding = React.lazy(
  () => import('./ListAccountsForwarding'),
);

const defaultParamsAccounts = {
  accountType: '',
};

const AccountsForwarding = createContext({
  params: {},
  dataAccounts: [],
  setParams: () => {},
  onUpdateListAccountsForwarding: () => {},
});

AccountsForwarding.displayName = 'AccountsForwarding';

export const useAccountsForwarding = () => {
  const context = useContext(AccountsForwarding);
  return context;
};

const LostAndOtherAccountsForwarding = () => {
  const { t }: any = useDictionary({
    programId: '2046.5',
  });
  const [dataLocal, setDataLocal] = useLocalStorage('2046.5_mst_dataList', {});
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParamsAccounts,
    startDate: new Date(),
    endDate: new Date(),
    formStore: {
      accountType: [],
      zone: [],
    },
    zone: '',
  });
  const [dataAccounts, setDataAccounts] = useState<any>([]);

  const [{ data, loading }, refetchAccountForwardingData] = useAxios(
    {
      url: LOST_OTHER_ACCOUNTS_FORWARDING_URL,
      method: 'POST',
    },
    { manual: true },
  );

  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const store: any = new ArrayStore({
    data: dataAccounts,
    key: 'otForwardId',
  });

  useEffect(() => {
    if (data?.data) {
      const dataMapping = (data?.data?.list || []).map(o => {
        o.statusNm = o?.status === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setDataAccounts(dataMapping);
    }
  }, [data]);

  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      formStore: {
        ...prev.formStore,
        zone: dataLocal?.zone || [],
      },
    }));
  }, [dataLocal]);

  const onUpdateListAccountsForwarding = (data, type) => {
    if (data) {
      data.statusNm = data?.status === '0' ? t('Pending') : t('Fixed');
    }
    if (type === 'add') {
      setDataAccounts(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    } else {
      store
        .update(data?.otForwardId, data)
        .done(() => {})
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh();
  };

  const value: any = {
    store,
    setModeView,
    modeView,
    params,
    setParams,
    refetchAccountForwardingData,
    onUpdateListAccountsForwarding,
    setDataLocal,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListAccountsForwarding ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateAccountsForwarding />;
      default:
        return <NotFoundPage />;
    }
  };

  const loadingAll = loading;
  return (
    <AccountsForwarding.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </AccountsForwarding.Provider>
  );
};

export default LostAndOtherAccountsForwarding;
