/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { RadioGroup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'utils/notification';
import ChartCountQuantityStock from './ChartCountQuantityStock';
import PopupProductSelectArea from './PopupProductSelectArea';
import PopupProductDetail from 'app/components/PopupCommon/PopupProductDetail';
import './styles.scss';

const TabReorderPoint = () => {
  const { t } = useDictionary({});
  const productAreaRef: any = useRef(null);
  const productDetailRef: any = useRef(null);
  const chartContainerRef = useRef<any>(null);
  const selectDivRef = useRef<any>(null);
  const startRef = useRef<any>(null);
  const [chartWrapper, setChartWrapper] = useState<any>(null);
  const [typeSelected, setTypeSelected] = useState<any>('1');
  const [chartData, setChartData] = useState<any>([]);
  const [chartTotal, setChartTotal] = useState<any>({});
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useEffect(() => {
    getDataChart();
  }, []);

  const getDataChart = async (type = '1') => {
    const res = await refetchData({
      url: '/account/reorder-point-rop',
      method: 'POST',
      data: { type: type, timezone: -(new Date().getTimezoneOffset() / 60) },
    });
    if (res?.data?.status === '200') {
      const { chartData = [], totalProduct = 0 } = res?.data?.data || {};
      const chartProductTotal = {
        category: '',
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        value5: 0,
      };
      const dataRes: any = [['x', 'y', { role: 'style' }, { role: 'hidden' }]];
      chartData?.forEach(o => {
        dataRes.push([
          o.stock,
          o.saleQty,
          'point { fill-color: #7F56D9; }',
          o.productId,
        ]);
        if (o.stock <= 1) {
          chartProductTotal.value1 += 1;
        } else if (o.stock > 1 && o.stock <= 2) {
          chartProductTotal.value2 += 1;
        } else if (o.stock > 2 && o.stock <= 3) {
          chartProductTotal.value3 += 1;
        } else if (o.stock > 3 && o.stock <= 4) {
          chartProductTotal.value4 += 1;
        } else if (o.stock > 4 && o.stock <= 5) {
          chartProductTotal.value5 += 1;
        }
      });
      const valueTotal =
        chartProductTotal.value1 +
          chartProductTotal.value2 +
          chartProductTotal.value3 +
          chartProductTotal.value4 +
          chartProductTotal.value5 || 1;

      setTypeSelected(type);
      setChartData(dataRes);
      setChartTotal({
        totalProduct:
          totalProduct / valueTotal > 6 ? valueTotal * 5 : totalProduct,
        data: chartData?.length ? [chartProductTotal] : [],
      });
    } else {
      notification({ res });
    }
  };

  const getDataPx = (x1, y1, x2, y2) => {
    const x3 = Math.min(x1, x2);
    const y3 = Math.min(y1, y2);
    const x4 = Math.max(x1, x2);
    const y4 = Math.max(y1, y2);
    const left = x3;
    const top = y3;
    const width = x4 - x3;
    const height = y4 - y3;
    return { left, top, width, height };
  };

  const reCalc = (x1, y1, x2, y2) => {
    const { left, top, width, height } = getDataPx(x1, y1, x2, y2);
    const selectDiv = selectDivRef.current;
    selectDiv.style.left = `${left}px`;
    selectDiv.style.top = `${top}px`;
    selectDiv.style.width = `${width}px`;
    selectDiv.style.height = `${height}px`;
    selectDiv.style.display = 'block';
    selectDiv.style.visibility = 'unset';
  };

  const handleMouseDown = e => {
    e?.preventDefault?.();
    const { left, top } = chartContainerRef?.current?.getBoundingClientRect();
    startRef.current = {
      startX: e.pageX - left,
      startY: e.pageY - top,
      endX: null,
      endY: null,
    };
  };

  const handleMouseMove = e => {
    e?.preventDefault?.();
    const { startX = null, startY = null } = startRef.current || {};
    if (startX == null || startY == null) {
      return;
    }
    const selectDiv = selectDivRef.current;
    if (!selectDiv.style.display) return;
    const { left, top } = chartContainerRef?.current?.getBoundingClientRect();
    const endX = e.pageX - left;
    const endY = e.pageY - top;
    startRef.current = { startX, startY, endX, endY };
    reCalc(startX, startY, endX, endY);
  };

  const handleMouseUp = e => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (!chartWrapper) return;
    let isClick = false;
    const selectDiv = selectDivRef.current;
    selectDiv.style.display = 'none';
    selectDiv.style.visibility = 'hidden';
    const chart = chartWrapper.getChart();
    const chartLayout = chart.getChartLayoutInterface();
    const { startX, startY, endX, endY } = startRef.current || {};
    let newStartX = startX;
    let newStartY = startY;
    let newEndX = endX;
    let newEndY = endY;
    if (!endX || !endY) {
      newStartX = startX - 15;
      newStartY = startY - 15;
      newEndX = startX + 15;
      newEndY = startY + 15;
      isClick = true;
    }
    const { left, top, width, height } = getDataPx(
      newStartX,
      newStartY,
      newEndX,
      newEndY,
    );
    const xMin = Math.min(left, left + width);
    const xMax = Math.max(left, left + width);
    const yMin = Math.min(top, top + height);
    const yMax = Math.max(top, top + height);

    const selection: any = [];
    const newData = chartData.map((row, index) => {
      if (index === 0) return row; // Header row
      const newRow = [...row];
      const point = chartLayout.getBoundingBox(`point#0#${index - 1}`);
      if (
        point &&
        point.left >= xMin &&
        point.left + point.width <= xMax &&
        point.top >= yMin &&
        point.top + point.height <= yMax
      ) {
        selection.push(row?.[3]);
        newRow[2] = 'point { fill-color: #ff0000; }';
        return newRow;
      }
      newRow[2] = 'point { fill-color: #7F56D9; }';
      return newRow;
    });
    startRef.current = null;
    setChartData(newData);
    if (!selection?.length) return;
    if (isClick && selection.length === 1) {
      productDetailRef.current?.onShowPopup(selection?.[0] || undefined);
    } else {
      productAreaRef?.current?.onOpen?.({
        type: typeSelected,
        productIds: selection,
        timezone: -(new Date().getTimezoneOffset() / 60),
      });
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: 5 }}>
      <LoadPanel visible={loading} />
      <div
        style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}
      >
        <RadioGroup
          layout={'horizontal'}
          items={[
            { text: t('Yesterday'), value: '1' },
            { text: t('Last week'), value: '2' },
            { text: t('Last month'), value: '3' },
          ]}
          defaultValue={typeSelected}
          valueExpr="value"
          displayExpr="text"
          onValueChanged={e => getDataChart(e?.value)}
        />
      </div>
      {chartData.length <= 1 ? (
        <p style={{ textAlign: 'center', marginTop: 100, height: 550 }}>
          {t('No data available to display')}
        </p>
      ) : (
        <div
          ref={chartContainerRef}
          style={{
            position: 'relative',
            width: '100%',
            height: '550px',
            margin: 'auto',
            backgroundColor: '#fff',
            borderRadius: 5,
            marginBottom: 10,
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <div
            ref={selectDivRef}
            style={{
              position: 'absolute',
              border: '1px dashed #DC6803',
              backgroundColor: 'rgba(51, 102, 204, 0.1)',
              display: 'none',
              visibility: 'hidden',
              zIndex: 1000,
            }}
          ></div>
          <Chart
            chartType="ScatterChart"
            width="100%"
            height="100%"
            data={chartData}
            className="scatter-chart-rop"
            options={{
              chartArea: {
                height: '100%',
                width: '100%',
                top: 32,
                left: 80,
                right: 20,
                bottom: 40,
              },
              hAxis: {
                title: t('Weeks Of Stock'),
                gridlines: {
                  count: 0,
                  color: '#eff1f6',
                },
                ticks: [0, 1, 2, 3, 4, 5],
                titleTextStyle: {
                  color: 'rgb(118, 118, 118)',
                  italic: false,
                  bold: false,
                },
                baselineColor: 'rgb(118, 118, 118)',
              },
              vAxis: {
                title: t('Sales Qty'),
                gridlines: {
                  color: '#eff1f6',
                },
                titleTextStyle: {
                  color: 'rgb(118, 118, 118)',
                  italic: false,
                  bold: false,
                },
                baselineColor: 'rgb(118, 118, 118)',
              },
              tooltip: { trigger: 'none' },
              legend: 'none',
              selectionMode: 'multiple',
            }}
            chartEvents={[
              {
                eventName: 'ready',
                callback: ({ chartWrapper }) => {
                  setChartWrapper(chartWrapper);
                },
              },
            ]}
          />
        </div>
      )}

      {chartTotal?.data?.length && (
        <ChartCountQuantityStock
          t={t}
          data={chartTotal?.data || []}
          totalProduct={chartTotal?.totalProduct}
        />
      )}

      <PopupProductSelectArea ref={productAreaRef} />
      <PopupProductDetail ref={productDetailRef} />
    </div>
  );
};

export default TabReorderPoint;
