/**
 * Update date: 2024-07-11
 * Screen 2024.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { memo, useRef, useState } from 'react';
import PopupDownloadData from './PopupDownloadData';
import PopupFileUploadFormData from './PopupFileUploadFormData';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
import PopupConfirmDeliveryBox from './PopupConfirmDeliveryBox';
import PopupInputDeliveryBoxQty from './PopupInputDeliveryBoxQty';
import { Form } from 'devextreme-react';
import { dateFormatStr } from 'utils/format';

function DeliverTotal({ t, storeTotal, storeSearchForm, setIsResearch }) {
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { QtyFormat } = useFormat();
  const formRef: any = useRef(null);
  const dataGridRef: any = useRef(null);
  const downloadRef: any = useRef(null);
  const confirmDeliverRef: any = useRef(null);
  const inputQtyRef: any = useRef(null);
  const [formData] = useState<any>({ forwardDt: Date() });
  const [file, setFile] = useState<any>(new Blob());
  const [isOpenModalImport, setIsOpenModalImport] = useState<any>({
    isShow: false,
    data: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  const onUpload = async data => {
    if (!file || !file?.size) {
      return notification({
        message: t('Please upload file'),
        type: 'error',
      });
    }
    const dataRequest: any = {
      zoneId: storeSearchForm?.zoneIds?.[0],
      vendorId: data?.vendorId,
      seqNo: data?.seqNo,
      forwardDt: dateFormatStr(formData?.forwardDt),
    };
    const formDataRequest = new FormData();
    formDataRequest.append('file', file);
    const res = await refetchData({
      url: 'warehouse/consigned-delivery/upload',
      method: 'POST',
      data: formDataRequest,
      params: dataRequest,
    });
    notification({ res });
    if (res?.data?.status == '200') {
      setIsResearch(true);
    }
    setIsOpenModalImport({ isShow: false });
  };

  const columns: any = [
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      showWhenGrouped: true,
    },
    {
      dataField: 'productTpNm',
      caption: t('Product Type'),
      showWhenGrouped: true,
    },
    {
      dataField: 'expectedDeliveryDt',
      caption: t('Expected Delivery Date'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'orderNumberCount',
      caption: t('Order Number (Qty)'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'skuCount',
      caption: t('SKU (Qty)'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'orderEaCount',
      caption: t('Order Qty (EA)'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },

    {
      dataField: 'allocateDm',
      caption: t('Allocate Date'),
      alignment: 'left',
      dataType: 'date',
      format: DateFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'allocateSeq',
      caption: t('Allocate Seq'),
      showWhenGrouped: true,
    },
    {
      dataField: 'printedDm',
      caption: t('Shipping Request Download'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveBill',
      caption: t('Product Receive Bill'),
      showWhenGrouped: true,
    },
    {
      dataField: 'forwardBill',
      caption: t('Product Foward Bill'),
      showWhenGrouped: true,
    },
    {
      dataField: 'shippingReceiveBill',
      caption: t('Shipping Box Receive Bill'),
      showWhenGrouped: true,
    },
    {
      caption: t('Download'),
      alignment: 'center',
      cellRender: record => {
        const {
          ID,
          vendorId,
          vendorNm,
          productTpNm,
          allocateDm,
          allocateSeq,
          expectedDeliveryDt,
        } = record?.data || {};
        return (
          <span
            className="cell-link"
            onClick={() =>
              downloadRef?.current?.onOpen?.({
                ...storeSearchForm,
                ID,
                vendorId,
                vendorNm,
                productTpNm,
                allocateDm,
                allocateSeq,
                expectedDeliveryDt,
              })
            }
          >
            {t('Download')}
          </span>
        );
      },
    },
    {
      caption: t('Upload'),
      alignment: 'center',
      cellRender: record => {
        const { ID, vendorId, vendorNm } = record?.data || {};
        return (
          <span
            className="cell-link"
            onClick={() => {
              if (!formRef?.current?.instance?.validate()?.isValid) return;
              setIsOpenModalImport({
                isShow: true,
                data: { ID, vendorId, vendorNm },
              });
            }}
          >
            {t('Upload')}
          </span>
        );
      },
    },
    {
      caption: t('Delivery Box'),
      alignment: 'center',
      cellRender: record => {
        const { vendorId, receiveId, allocateDm, allocateSeq } =
          record?.data || {};
        return (
          <>
            {receiveId && (
              <span
                className="cell-link"
                onClick={() => {
                  confirmDeliverRef?.current?.onOpen?.({
                    vendorId,
                    receiveIds: receiveId,
                    receiveDate: dateFormatStr(formData?.forwardDt),
                    referenceCd: `${allocateDm}${allocateSeq || 0}`,
                  });
                }}
              >
                {t('Input Delivery Box Qty')}
              </span>
            )}
          </>
        );
      },
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName = `2024.3 Shipping Request Total (by Vendor)_
    ${moment().format('YYYYMMDD')}`;

  return (
    <>
      <div className="bg-white" style={{ paddingRight: 10, paddingTop: 10 }}>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="left"
          colCount={3}
          items={[
            { itemType: 'empty', colSpan: 2 },
            {
              label: { text: t('Forward Date') },
              dataField: 'forwardDt',
              editorType: 'dxDateBox',
              editorOptions: { displayFormat: DateFormat },
              isRequired: true,
            },
          ]}
        />
      </div>

      <DataGridFull
        storageKeyInquiry="v2"
        columns={columns}
        ref={dataGridRef}
        dataSource={storeTotal}
        fixedLeft={2}
        fixedRight={3}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !storeTotal?._array?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
        }}
      />

      <PopupDownloadData
        ref={downloadRef}
        onOk={data => {
          storeTotal?.update(data?.ID, { printedDm: data?.printedDm });
          dataGridRef?.current?.instance?.refresh();
        }}
      />
      <PopupConfirmDeliveryBox
        ref={confirmDeliverRef}
        onOk={data => {
          inputQtyRef?.current?.onOpen?.({ ...data });
        }}
      />
      <PopupInputDeliveryBoxQty
        ref={inputQtyRef}
        onOk={() => setIsResearch(true)}
      />

      {isOpenModalImport.isShow && (
        <PopupFileUploadFormData
          visible={isOpenModalImport.isShow}
          data={isOpenModalImport?.data}
          onHiding={() => {
            setIsOpenModalImport({ isShow: false });
            setFile(new Blob());
          }}
          onOk={onUpload}
          onSelectedFile={file => file && setFile(file[0])}
        />
      )}
      <LoadPanel visible={loading} />
    </>
  );
}

export default memo(DeliverTotal);
