/**
 * Update date: 2024-11-29
 * Sales Trend
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { ScrollView } from 'devextreme-react';
import { memo, useEffect, useState } from 'react';

import moment from 'moment';
import { notification } from 'utils/notification';
import ChartDetailProduct from './ChartDetailProduct';

const SalesTrendTab = ({ productId }) => {
  const [chartData, setChartData] = useState<any>(null);
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useEffect(() => {
    if (!productId) return;
    const getDataChart = async () => {
      const res = await refetchData({
        url: '/account/reorder-point-rop/product-chart-detail',
        method: 'GET',
        params: {
          productId: productId,
          timezone: -(new Date().getTimezoneOffset() / 60),
        },
      });
      if (res?.data?.status === '200') {
        const resData = res?.data?.data || {};
        const {
          currentProductChart = [],
          lastProductChart = [],
          currentEvent = [],
          lastEvent = [],
          orderChart = [],
        } = resData;
        const allDateEvent: any = [];
        const combinedStock: any = [
          ...currentProductChart,
          ...lastProductChart,
        ].map(o => {
          allDateEvent.push(new Date(o?.date));
          return {
            ...o,
            date: moment(o?.date).format('MM/DD'),
          };
        });
        const combinedEvent = [...currentEvent, ...lastEvent].map(o => ({
          ...o,
          startDate: new Date(o?.startDate),
          endDate: new Date(o?.endDate),
        }));
        resData.combinedStock = combinedStock;
        resData.combinedEvent = combinedEvent;
        resData.allDateEvent = allDateEvent;
        resData.allDateOrder = (currentProductChart || []).map(o => o?.date);
        resData.orderChart = orderChart.map(o => ({
          ...o,
          date: new Date(o?.date),
        }));
        setChartData(resData);
      } else {
        notification({ res });
      }
    };
    getDataChart();
  }, [productId]);

  return (
    <>
      <ScrollView
        width="100%"
        height={'calc(95vh - 110px)'}
        direction="vertical"
        showScrollbar="onHover"
      >
        <div>
          {chartData && <ChartDetailProduct chartData={chartData || {}} />}
        </div>
      </ScrollView>
      <LoadPanel visible={loading} />
    </>
  );
};

export default memo(SalesTrendTab);
