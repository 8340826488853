/**
 * Update date: 26-05-2023
 * Screen 2043.4
 */
import React, { useRef, useState } from 'react';
import { Form } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { useModuleContext } from '..';
import PopupAddProduct from 'app/components/PopupCommon/PopupAddProduct';
import PopupAddContainer from 'app/components/PopupCommon/PopupAddContainer';
import '../styles.scss';
import useFormatTime from 'hooks/useFormatTime';
import { dateFormatStr } from 'utils/format';

function FormAddProduct() {
  const context: any = useModuleContext();
  const {
    addProductOrContainer,
    addMultiProduct,
    formRef,
    refetchData,
    formInfo,
    DoubleFormat,
    AmountFormat,
    QtyFormat,
  } = context;
  const [formData, setFormData] = useState<any>({
    productName: '',
    containerName: '',
  });
  const formAddRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const [showProduct, setShowProduct] = useState<boolean>(false);
  const [showContainer, setShowContainer] = useState<boolean>(false);

  const { t }: any = useDictionary({
    programId: '2043.4',
  });

  const productColumns: any = [
    {
      dataField: 'productId',
      caption: t('Product Id'),
    },
    {
      dataField: 'productTypeName',
      caption: t('Product Type'),
      allowSearch: false,
    },
    {
      dataField: 'tradeTp',
      caption: t('Trade Type'),
      cellRender: e => {
        switch (e?.value) {
          case '0':
            return <span>{t('Traded')}</span>;
          case '1':
            return <span>{t('Non Traded')}</span>;
          case '2':
            return <span>{t('Discontinued')}</span>;
          default:
            return '';
        }
      },
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      allowSearch: false,
    },
    {
      dataField: 'latestPurchaseUnitPrice',
      caption: t('Last Purchase Unit Price'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'makerName',
      caption: t('Maker'),
    },
    {
      dataField: 'vendorId',
      caption: t('VendorId'),
      visible: false,
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor'),
    },
    {
      dataField: 'productName',
      caption: t('Product'),
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketablesize'),
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
    },
    {
      dataField: 'option',
      caption: t('Option'),
    },
    {
      dataField: 'supplyUnitPrice',
      caption: t('Supply Unit Price'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'stockEa',
      caption: t('Stock EA'),
      dataType: 'number',
    },
    {
      dataField: 'availableStock',
      caption: t('Available Stock'),
      dataType: 'number',
      format: QtyFormat,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      dataType: 'number',
      format: QtyFormat,
    },
    {
      dataField: 'finalSupplyDate',
      caption: t('Final Supply Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'dealName',
      caption: t('Deal Name'),
    },
  ];

  const containerColumns: any = [
    {
      dataField: 'containerid',
      caption: t('ID'),
      alignment: 'left',
    },
    {
      dataField: 'producttypename',
      caption: t('Product Type'),
      allowSearch: false,
    },
    {
      dataField: 'containercode',
      caption: t('Container Code'),
    },
    {
      dataField: 'containername',
      caption: t('Container'),
    },
    {
      dataField: 'containertypename',
      caption: t('Container Type'),
    },
    {
      dataField: 'containerkindname',
      caption: t('Container Kind'),
    },
    {
      dataField: 'capacity',
      caption: t('Capacity'),
    },
    {
      dataField: 'commoncontainercode',
      caption: t('Common Container Code'),
    },
    {
      dataField: 'containerunitprice',
      caption: t('Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'latestpurchaseunitprice',
      caption: t('Final Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
    },
  ];

  /**
   * on product
   *
   * @return {*}
   */
  const handleProduct = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    setShowProduct(!showProduct);
  };

  /**
   * on container
   *
   * @return {*}
   */
  const handleContainer = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    setShowContainer(!showContainer);
  };

  /**
   * on add product
   *
   * @param {*} e
   * @return {*}
   */
  const onAddProduct = async products => {
    if (!products?.length) {
      return setShowProduct(false);
    }
    products.forEach(p => {
      p.originalSupplyUnitPrice = p?.supplyUnitPrice || 0;
      p.productSku = `${p?.productId}${p?.optionId}`;
      p.availableStock = p.availableStock || 0;
    });

    addMultiProduct(products);
    setFormData({
      ...formData,
      productName: '',
    });
    setShowProduct(false);
  };

  /**
   * on add product
   *
   * @param {*} e
   * @return {*}
   */
  const onAddContainer = async e => {
    if (!e?.length) {
      return setShowContainer(false);
    }
    const data = e[0];
    const containerAdd: any = {
      addShippingPrice: 0,
      bottleId: null,
      containerId: data?.containerid,
      productId: data?.containerid,
      productCode: data?.containercode,
      productName: data?.containername,
      marketableSize: '',
      option: '*',
      optionName: '*',
      optionId: 1,
      quantityPerPack: 1,
      taxRate: 0,
      shippingRate: 0,
      supplyUnitPrice: data?.supplyUnitPrice,
      purchaseUnitPrice: data?.purchaseUnitPrice,
      receiveQuantity: data?.receiveQuantity,
      containerUnitPrice: data?.containerunitprice,
      note: '',
      classify: '1',
      bottleUnitPrice: data?.bottleUnitPrice,
      productType: data?.producttype,
      productTypeName: data?.producttypename,
      payUnitPrice: data?.containerunitprice,
      mallSellUnitPrice: data?.containerunitprice,
      marginRate: 0,
      availableStock: data?.availablestock || 0,
    };

    const res = await refetchData({
      url: '/warehouse/common/product-Sku',
      method: 'GET',
      params: {
        productId: containerAdd?.containerId,
        optionId: containerAdd?.optionId,
        classify: containerAdd?.classify,
      },
    });
    containerAdd.productSku = res?.data?.data || '';
    addProductOrContainer(containerAdd);
    setFormData({
      ...formData,
      containerName: '',
    });
    setShowContainer(false);
  };

  /**
   * on content ready
   *
   */
  const onContentReady = () => {
    setTimeout(function () {
      formAddRef?.current?.instance?.getEditor('productName')?.focus?.();
    }, 800);
  };

  /**
   * render form
   *
   * @return {*}
   */
  const FormPrdCnt = () => {
    return (
      <>
        <Form
          ref={formAddRef}
          showColonAfterLabel={false}
          formData={formData}
          labelLocation="top"
          colCount={3}
          className={'prdCnt body-padding-white p-15'}
          onContentReady={onContentReady}
          items={[
            {
              label: {
                text: t('Product'),
              },
              dataField: 'productName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => handleProduct(),
                buttons: [
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleProduct(),
                    },
                  },
                ],
              },
            },
            // {
            //   label: {
            //     text: t('Container'),
            //   },
            //   dataField: 'containerName',
            //   editorType: 'dxTextBox',
            //   editorOptions: {
            //     onEnterKey: () => handleContainer(),
            //     buttons: [
            //       {
            //         name: 'search',
            //         location: 'after',
            //         options: {
            //           stylingMode: 'text',
            //           icon: 'search',
            //           onClick: () => handleContainer(),
            //         },
            //       },
            //     ],
            //   },
            // },
          ]}
        ></Form>
      </>
    );
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <FormPrdCnt />
      {showProduct && (
        <PopupAddProduct
          text={formData?.productName}
          visible={showProduct}
          columns={productColumns}
          url="ecommerce/phone-orders/product"
          dataBody={{
            storeId: formInfo?.dataList?.store[0]?.storeNo,
            shippingMethod: formInfo?.dataList?.shipmethod[0]?.code,
            orderDate: dateFormatStr(formInfo?.ordDate),
            keySearch: formData?.productName,
          }}
          onHiding={() => setShowProduct(!showProduct)}
          onSubmit={onAddProduct}
          mode="multiple"
        />
      )}
      {showContainer && (
        <PopupAddContainer
          text={formData?.containerName}
          columns={containerColumns}
          visible={showContainer}
          onHiding={() => setShowContainer(!showContainer)}
          url="ecommerce/phone-orders/container"
          method={'GET'}
          onSubmit={onAddContainer}
          isConverPrice={false}
        />
      )}
    </React.Suspense>
  );
}

export default FormAddProduct;
