/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import PopupProductOrder from 'app/components/PopupCommon/PopupProductOrder';

interface IPopupProductSelectArea {
  onOk?: (data?: any) => void;
}
const PopupProductSelectArea = ({ onOk }: IPopupProductSelectArea, ref) => {
  const { t }: any = useDictionary({});
  const dataGridRef: any = useRef(null);
  const popupOrderRef: any = useRef(null);
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [productData, setProductData] = useState<any>([]);
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = async data => {
    if (!data) return;
    setShowPopupConfirm(true);
    const res = await refetchData({
      url: '/account/reorder-point-rop/product-area-detail',
      method: 'POST',
      data: data,
    });
    if (res?.data?.status === '200') {
      setProductData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  const onClickNo = () => {
    setShowPopupConfirm(false);
    setProductData([]);
  };

  /**
   * Render action column grid
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    return (
      <>
        <span
          onClick={event => {
            popupOrderRef.current?.onShowPopup(
              record?.data?.product_id || undefined,
            );
            event.stopPropagation();
          }}
          className="cell-link"
        >
          {t('Order')}
        </span>
      </>
    );
  };

  const columns: any = [
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'product_tp_nm',
          caption: t('Product Type'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_id',
          caption: t('Product ID'),
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_cd',
          caption: t('Product Code'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_nm',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_maker_nm',
          caption: t('Maker'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'marketable_size',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'maker',
          caption: t('Maker'),
          visible: false,
        },
        {
          dataField: 'origin',
          caption: t('Origin'),
          visible: false,
        },
        {
          dataField: 'vendor_nm_default',
          caption: t('Default Vendor'),
          visible: false,
        },
        {
          dataField: 'largecategory',
          caption: t('Large Category'),
          visible: false,
        },
        {
          dataField: 'mediumcategory',
          caption: t('Medium Category'),
          visible: false,
        },
        {
          dataField: 'smallcategory',
          caption: t('Small Category'),
          visible: false,
        },
        {
          dataField: 'subcategory',
          caption: t('Sub Category'),
          visible: false,
        },
      ],
    },
    {
      caption: t('Receive'),
      alignment: 'center',
      columns: [
        {
          dataField: 'last_receive_vendor_nm',
          caption: t('Final Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'min_receive_date',
          caption: t('First Receive Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'max_receive_date',
          caption: t('Last Receive Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_ea',
          caption: t('Period Receive ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_amount',
          caption: t('Period Receive Amount'),
          format: AmountFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'receive_count',
          caption: t('Period Receive Count'),
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'min_forward_date',
          caption: t('First Forward Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'max_forward_date',
          caption: t('Last Forward Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forward_ea',
          caption: t('Period Forward Ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'forward_amount',
          caption: t('Period Forward Amount'),
          format: AmountFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forward_count',
          caption: t('Period Forward Count'),
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Stock'),
      alignment: 'center',
      columns: [
        {
          dataField: 'stock_ea',
          caption: t('Stock Ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'forward_7days',
          caption: t('Last 7days sale'),
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_ratio_stock',
          caption: t('Receive Stock Ratio'),
          showWhenGrouped: true,
        },
        {
          dataField: 'stock_week_index',
          caption: t('Stock Week Index'),
          format: DoubleFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Action'),
      alignment: 'center',
      fixedPosition: 'right',
      fixed: true,
      width: 80,
      cellRender: renderAction,
    },
  ];

  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <div className="modal-popup-header">
            <span className="title-page">{t('Product Selected')}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onClickNo}
              />
            </div>
          </div>
          <DataGridFull
            columns={columns}
            ref={dataGridRef}
            dataSource={productData}
            fixedLeft={0}
            fixedRight={1}
            options={{
              columnAutoWidth: true,
              scrolling: { columnRenderingMode: 'virtual' },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
            }}
          />
          <PopupProductOrder ref={popupOrderRef} />
        </div>
      </ScrollView>
    );
  };

  return (
    <>
      <Popup
        className="modal-content-popup popup-custom-css"
        visible={showPopupConfirm}
        contentRender={content}
        height={isMobile ? '80vh' : '100vh'}
        width={isMobile ? '96%' : 'calc(100vw - 256px)'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
      <LoadPanel visible={loading} />
    </>
  );
};

export default memo(forwardRef(PopupProductSelectArea));
