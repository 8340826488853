/**
 * Update date: 23-05-2023
 * Screen 2023.1
 */
import { Form, Template } from 'devextreme-react';
import React, { useState, forwardRef } from 'react';
import { useOrderRegistration } from '..';
import { useFormCreate } from '.';
import PopupAddProduct from 'app/components/PopupCommon/PopupAddProduct';
import PopupAddContainer from 'app/components/PopupCommon/PopupAddContainer';
import BarcodeTemplate from 'app/components/Templates/Barcode';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
import useAxios from 'axios-hooks';
import { dateFormatStr } from 'utils/format';
const urlBarCode = '/warehouse/receive/product-barcode';

const FormAddProduct = (props, ref) => {
  const orderContext: any = useOrderRegistration();
  const { modeView, t } = orderContext;
  const createOrderContext: any = useFormCreate();
  const { onAddOrder, formSearchRef, focusItem } = createOrderContext || {};

  const billId = orderContext?.modeView?.id;
  const [formData, setFormData] = useState<any>({
    barcode: '',
    productName: '',
    containerName: '',
    barcodes: [],
  });
  const [showModalSelectContainer, setShowModalSelectContainer] =
    useState(false);
  const [showModalSelectProduct, setShowModalSelectProduct] = useState(false);

  const [{ loading: loadingPurchaseUnitPrice }, purchaseUnitPrice] = useAxios(
    {
      url: '/warehouse/receive/fun-vendor-purchase-unit-price',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{ loading: loadingPriceMultiProduct }, vendorPriceMultiProduct] =
    useAxios(
      {
        url: '/warehouse/receive/get-vendor-purchase-unit-price-multi-product',
        method: 'POST',
      },
      { manual: true, useCache: false, autoCancel: true },
    );

  /**
   * on set barcode
   *
   * @param {*} data
   */
  const onSetBarcode = data => {
    ref?.current?.instance?.updateData('barcodes', data);
  };

  /**
   * on add barcode
   *
   * @param {*} data
   */
  const onAddBarcode = async data => {
    const { deliverDueDate, formStore } =
      formSearchRef?.current?.instance?.option()?.formData || {};
    const vendorId = formStore?.vendor?.vendorId || null;

    const res = await purchaseUnitPrice({
      data: {
        inDate: dateFormatStr(deliverDueDate),
        inVendorId: vendorId,
        inProductId: data?.productId,
        inOptionId: data?.optionId,
      },
    });
    data.purchaseUnitPrice = res?.data?.data || 0;
    data.taxRate = data?.purchaseTaxRate || 0;
    onAddOrder([data], 1, '0');
  };

  /**
   * on add product
   *
   * @param {*} list
   */
  const onAddProduct = async (list: any) => {
    if (list?.length) {
      const { deliverDueDate, formStore } =
        formSearchRef?.current?.instance?.option()?.formData || {};
      const vendorId = formStore?.vendor?.vendorId || null;
      const res = await vendorPriceMultiProduct({
        data: {
          inDate: dateFormatStr(deliverDueDate),
          inVendorId: vendorId,
          productData: list.map(p => ({
            inProductId: p?.productId,
            inOptionId: p?.optionId,
          })),
        },
      });
      if (res?.data?.status === '200') {
        (res?.data?.data || []).forEach(p => {
          const dataFound = list?.find(
            o => o.productId == p.inProductId && o.optionId == p.inOptionId,
          );
          if (dataFound) {
            dataFound.purchaseUnitPrice = p?.purchaseUnitPrice || 0;
          }
        });
      } else {
        notification({ res });
        return;
      }
      onAddOrder(list, 2, '0');
      setFormData({
        ...formData,
        productName: '',
      });
    }
    setShowModalSelectProduct(!showModalSelectProduct);
  };

  /**
   * on add container
   *
   * @param {*} list
   */
  const onAddContainer = (list: any) => {
    if (list?.length) {
      onAddOrder(list, 3, '1');
      setFormData({
        ...formData,
        containerName: '',
      });
    }
    setShowModalSelectContainer(!showModalSelectContainer);
  };

  /**
   * on valid params
   *
   * @return {*}
   */
  const isValidParams = () => {
    const { ctOrderDt, deliverDueDate, formStore } =
      formSearchRef?.current?.instance?.option()?.formData || {};
    const vendorId = formStore?.vendor?.vendorId || null;

    switch (true) {
      case !vendorId:
        notification({
          message: t('You need input data field Vendor!'),
          type: 'error',
        });
        return false;
      case !deliverDueDate:
        notification({
          message: t('You need input data field Deliver Due Date!'),
          type: 'error',
        });
        return false;
      case !ctOrderDt:
        notification({
          message: t('You need input data field Date!'),
          type: 'error',
        });
        return false;
      default:
        break;
    }
    return true;
  };

  /**
   * on content ready
   *
   */
  const onContentReady = () => {
    setTimeout(function () {
      if (focusItem === 2) {
        ref?.current?.instance?.getEditor('productName')?.focus?.();
      } else if (focusItem === 3) {
        ref?.current?.instance?.getEditor('containerName')?.focus?.();
      }
    }, 800);
  };

  /**
   * render content
   */
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel
        visible={loadingPurchaseUnitPrice || loadingPriceMultiProduct}
      />
      <Form
        ref={ref}
        formData={formData}
        colCount={3}
        style={{ marginTop: 10 }}
        labelLocation="top"
        showColonAfterLabel={false}
        readOnly={modeView?.type === 'view'}
        onContentReady={onContentReady}
        items={[
          {
            itemType: 'simple',
            template: 'barcode',
            label: {
              text: t('Barcode'),
            },
          },
          {
            label: {
              text: t('Search for the product'),
            },
            dataField: 'productName',
            editorType: 'dxTextBox',
            editorOptions: {
              onEnterKey: () => {
                if (!isValidParams()) return;
                setShowModalSelectProduct(!showModalSelectContainer);
              },
              buttons: [
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => {
                      if (!isValidParams()) return;
                      setShowModalSelectProduct(!showModalSelectContainer);
                    },
                  },
                },
              ],
            },
          },
          {
            label: {
              text: t('Search for the container'),
            },
            dataField: 'containerName',
            editorType: 'dxTextBox',
            editorOptions: {
              onEnterKey: () =>
                setShowModalSelectContainer(!showModalSelectContainer),
              buttons: [
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () =>
                      setShowModalSelectContainer(!showModalSelectContainer),
                  },
                },
              ],
            },
          },
        ]}
      >
        <Template
          name="barcode"
          component={() => (
            <BarcodeTemplate
              billId={billId}
              url={urlBarCode}
              onEnter={onAddBarcode}
              onSetBarcode={onSetBarcode}
              typeBarCode={8}
              readOnly={modeView?.type === 'view'}
              params={{ optionCtOrderTf: true }}
              isValidParams={isValidParams}
              isFocus={focusItem === 1 && modeView?.type !== 'view'}
            />
          )}
        />
      </Form>
      {showModalSelectProduct && (
        <PopupAddProduct
          text={formData?.productName}
          visible={showModalSelectProduct}
          onHiding={() => setShowModalSelectProduct(!showModalSelectProduct)}
          onSubmit={onAddProduct}
          isPurchase={true}
          dataBody={{
            optionCtOrderTf: true,
            vendorId: formSearchRef?.current?.instance?.option()?.formData
              ?.productVendor
              ? formSearchRef?.current?.instance?.option()?.formData?.formStore
                  ?.vendor?.vendorId
              : null,
          }}
          mode="multiple"
        />
      )}
      {showModalSelectContainer && (
        <PopupAddContainer
          text={formData?.containerName}
          visible={showModalSelectContainer}
          onHiding={() =>
            setShowModalSelectContainer(!showModalSelectContainer)
          }
          onSubmit={onAddContainer}
        />
      )}
    </React.Suspense>
  );
};

export default forwardRef(FormAddProduct);
