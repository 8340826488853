/**
 * Update date: 26-05-2023
 * Screen 2043.4
 */
import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import useAxios from 'axios-hooks';
import TableView from './TableView';
import FormDetail from './FormDetail';
import FormAddProduct from './FormAddProduct';
import ArrayStore from 'devextreme/data/array_store';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { cloneDeep } from 'lodash';
import { notification } from 'utils/notification';
import useLocalStorage from 'hooks/useLocalStorage';
import useFormat from 'hooks/useFormat';
import { dateFormatStr } from 'utils/format';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

const RetrieveOrder = React.lazy(() => import('./RetrieveOrder'));

export const dafaultInfo2043_4 = {
  ordDate: new Date(),
  receiver: '',
  phoneNo: '',
  mobileNo: '',
  postCode: '',
  basicAdd: '',
  detailAdd: '',
  supplyUnitPriceGroup: '',
  shipment_tp: '',
  deliveryRequestDm: new Date(),
  orderNumber: null,
  shippingMessage: null,
  dataList: {
    mallCode: [],
    store: [],
    shipmethod: [],
  },
  shipmethod: '',
  store: '',
  mallCode: '',
};

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2043_4_ModuleContext';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2043_4 = () => {
  const { t } = useDictionary({ programId: '2043.4' });
  const [modeView, setModeView] = useState<any>({
    type: 'list',
  });
  const [formInfo, setFormInfo] = useState(
    cloneDeep({
      ...dafaultInfo2043_4,
      ordDate: new Date(),
      deliveryRequestDm: new Date(),
    }),
  );
  const [dataLocal, setDataLocal] = useLocalStorage('2043.4_dataList', {});
  const [products, setProducts] = useState<any>([]);
  const dataGridRef: any = useRef(null);
  const formRef: any = useRef(null);

  const { DoubleFormat, AmountFormat, QtyFormat }: any = useFormat();

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: products,
    key: ['productId', 'optionId'],
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormInfo(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  useEffect(() => {
    if (!modeView?.order) return;
    const { order, orderProducts } = modeView?.order;
    setFormInfo(prev => ({
      ...prev,
      mallCode: 'AOS_C',
      oldOrderId: order?.orderId,
      receiver: order?.receiverName,
      phoneNo: order?.receiverPhone,
      mobileNo: order?.receiverMobile,
      postCode: order?.receiverZipcode,
      basicAdd: order?.receiverAddress1,
      detailAdd: order?.receiverAddress2,
      orderNumber: order?.orderNumber,
      dataList: {
        ...prev?.dataList,
        store: [{ storeNo: order?.storeId, storeNm: order?.storeNm }],
        shipmethod: [
          { code: order?.shippingMethod, name: order?.shippingMethodNm },
        ],
      },
    }));
    setProducts(
      (orderProducts || []).map(o => ({
        productId: o?.productId,
        productCode: o?.productCd,
        productName: o?.productNm,
        optionId: o?.optionId,
        optionCode: o?.optionCd,
        option: o?.optionNm,
        marketableSize: o?.marketableSize,
        classify: o?.classify,
        productType: o?.productTp,
        taxRate: o?.taxRate,
        productSku: o?.productSku,
        marginRate: o?.marginRate,
        quantityPerPack: o?.quantityPerPack,
        containerId: o?.optionContainerId,
        bottleId: o?.bottleId,
        containerUnitPrice: o?.containerUnitPrice,
        bottleUnitPrice: o?.bottleUnitPrice,
        receiveQuantity: o?.receiveQuantity,
        supplyUnitPrice: o?.supplyUnitPrice,
        addShippingPrice: o?.addShippingPrice,
        shippingRate: o?.shippingRate,
        mallSellUnitPrice: o?.mallSellUnitPrice,
        availableStock: o?.availableStock,
        note: '',
      })),
    );
    setModeView(prev => {
      prev.order = null;
      return prev;
    });
  }, [modeView]);

  /**
   * on calculate unit price
   *
   * @param {*} storeId
   * @param {*} shipmentTp
   * @return {*}
   */
  const calculateUnitPrice = async (storeId, shipmentTp, ordDate) => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const productDetails = store?._array
      .filter(o => o.classify == '0')
      .map(o => ({
        productId: o?.productId,
        optionId: o?.optionId,
        supplyUnitPrice: o?.supplyUnitPrice || 0,
        shippingRate: o?.shippingRate || 0,
      }));

    if (!productDetails?.length) return;
    const res = await refetchData({
      url: 'ecommerce/process-order-upload/recalc-supply-unit-price',
      method: 'POST',
      data: {
        storeId: storeId,
        shipmentTp: shipmentTp,
        date: dateFormatStr(ordDate),
        productDetails: productDetails,
      },
    });
    if (res?.data?.status == '200') {
      const lengthData = (res?.data?.data || [])?.length;
      (res?.data?.data || []).forEach((o, idx) => {
        store
          .update(
            { productId: o?.productId, optionId: o?.optionId },
            {
              supplyUnitPrice: o?.supplyUnitPrice || 0,
              shippingRate: o?.shippingRate || 0,
            },
          )
          .done(() => {
            if (idx === lengthData - 1) {
              setTimeout(() => {
                dataGridRef?.current?.instance?.refresh?.();
              });
            }
          })
          .fail(() => {});
      });
    } else {
      notification({
        message: t('Fail to calculate unit price of the products'),
        type: 'error',
      });
    }
  };

  /**
   * on add product or container
   *
   * @param {*} e
   */
  const addProductOrContainer = async e => {
    const gridData: any = [...products];
    const data = gridData?.find(
      o => o?.optionId === e?.optionId && o?.productId === e?.productId,
    );

    if (data) {
      const newData = {
        ...data,
        receiveQuantity: +data?.receiveQuantity + 1,
      };
      store
        .update(
          { productId: data.productId, optionId: data?.optionId },
          newData,
        )
        .done(() => store.load());
    } else {
      store
        .insert({
          ...e,
          receiveQuantity: 1,
        })
        .done(() => store.load())
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh?.();
  };

  /**
   * on add product or container
   *
   * @param {*} e
   */
  const addMultiProduct = async data => {
    const gridData: any = [...products];
    (data || []).forEach((e, index) => {
      const dataFound = gridData?.find(
        o => o?.optionId === e?.optionId && o?.productId === e?.productId,
      );

      if (dataFound) {
        const newData = {
          ...dataFound,
          receiveQuantity: +dataFound?.receiveQuantity + 1,
        };
        store.update(
          { productId: dataFound.productId, optionId: dataFound?.optionId },
          newData,
        );
      } else {
        store
          .insert({
            ...e,
            receiveQuantity: 1,
          })
          .fail(() => {});
      }
      if (index + 1 === data?.length) {
        store.load();
        dataGridRef?.current?.instance?.refresh?.();
      }
    });
  };

  const value: any = {
    t,
    store,
    formRef,
    formInfo,
    setFormInfo,
    products,
    setProducts,
    refetchData,
    calculateUnitPrice,
    addProductOrContainer,
    addMultiProduct,
    dataLocal,
    setDataLocal,
    DoubleFormat,
    AmountFormat,
    QtyFormat,
    setModeView,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return (
          <>
            <BreadCrumbPremium />
            <FormDetail ref={formRef} />
            <FormAddProduct />
            <TableView ref={dataGridRef} />
          </>
        );
      case 'retrieve':
        return <RetrieveOrder />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2043_4;
